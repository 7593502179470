import React from "react"
import Nav from "../components/nav"
import ContentContainer from "../components/contentContainer"

export default () => (
  <ContentContainer>
    <Nav />
    <h1>Dis' me about</h1>
  </ContentContainer>
)
